import React, { useEffect, useState } from "react";
import axios from "axios";
import schedule from 'node-schedule';
import { subMonths, subYears, subDays, format } from "date-fns";
import GraficoAssinantesAtivos from "./graficoAssinantesAtivos";
import GraficoCancelados from "./graficoCancelados";
import GraficoFaturamentoMensal from "./graficoFaturamento";
import GraficoTicketMedio from "./graficoTicketMedio";
import GraficoTestesGratuitos from "./graficoTestesGratuitos";

import "./DashboardGrid.css";

let executouHoje = false;
const processedUsers = new Set();

const GET_USERS_URL_STORE =
  "https://dashboard-api-bice.vercel.app/api/consultar_usuarios/";
const GET_USERS_URL_SUBS =
  "https://dashboard-api-bice.vercel.app/api/consultar_usuarios_assinantes/";
const GET_USERS_URL_CANCELADOS =
  "https://dashboard-api-bice.vercel.app/api/consultar_assinantes_cancelados/";


function DashboardGrid() {
  const [Usuarios, setUsuarios] = useState([]);
  const [Usuarios_assinantes, setUsuariosAssinantes] = useState([]);
  const [Usuarios_cancelados, setUsuariosCancelados] = useState([]);
  const [graficoAtual, setGraficoAtual] = useState("assinantesAtivos");
  const handleTrocarGrafico = (novoGrafico) => {
    setGraficoAtual(novoGrafico);
  };

  const handleAtualizarDados = async () => {
    try {
      await fetch("https://api.unmep.com.br/atualizardashboard", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.status === 200) {
          alert("Dados atualizados com sucesso!")
          window.location.reload();
        } else {
          alert("Ocorreu uma falha ao atualizar : " + res.status)
        }
      });

    } catch (err) {
      alert("Falha ao atualizar os dados: " + err)
    }
  };

  // Até aqui é para pegar os dados dos bancos principais e passar para o banco do dashboard
  // Agora é para pegar os dados do banco do dashboard e passar para o gráfico


  function removerDuplicatasPorStripeId(arr) {
    const uniqueMap = new Map();

    for (const user of arr) {
      // Use stripe_id como chave no mapa para garantir exclusividade
      uniqueMap.set(user.stripe_id, user);
    }

    // Converta os valores do mapa (objetos exclusivos) de volta para uma array
    const uniqueUsers = Array.from(uniqueMap.values());

    return uniqueUsers;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          axios(GET_USERS_URL_SUBS),
          axios(GET_USERS_URL_STORE),
          axios(GET_USERS_URL_CANCELADOS),
        ]).then(res => {
          const subsUsers = res[0].data;
          const storeUsers = res[1].data;
          const canceladosResponse = res[2].data;

          let canceladosUsers = removerDuplicatasPorStripeId(canceladosResponse);

          const updatedUsuariosGeral = storeUsers.map(geralUser => {
            const matchingMensaisUser = subsUsers.find(mensaisUser => mensaisUser.stripe_id === geralUser.stripe_id);
            if (matchingMensaisUser) {
              return {
                ...geralUser,
                datadecompra: matchingMensaisUser.data_compra,
                proximarenovacao: matchingMensaisUser.next_renew,
                data_cancelamento: matchingMensaisUser.last_renew,
              };
            }
            return geralUser;
          });

          setUsuariosAssinantes(subsUsers);
          setUsuariosCancelados(canceladosUsers);
          setUsuarios(updatedUsuariosGeral);

        });

      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      }
    };

    fetchData();
  }, []);

  const mesAtual = new Date();
  const mesSeguinte = new Date();
  mesAtual.setDate(1);
  mesSeguinte.setDate(2);
  var assinantes_geral = {};
  var assinantes_anuais_ = {};

  const permitidos = ["plano premium", "plano combo", "plano basic", "jamal", "parceiro unmep", "union/unmep", "teste gratuito"];

  if (Object.keys(Usuarios).length !== 0 && Usuarios_assinantes.length !== 0 && Usuarios_cancelados.length !== 0) {
    var datadehoje = new Date();
    assinantes_geral = Usuarios.filter((user) =>
      user.subscriptionstatus === true &&
      user.subscriptionexpired > datadehoje.toISOString() &&
      permitidos.includes(user.tipo_assinatura.toLowerCase())
    );

    assinantes_anuais_ = Usuarios.filter((user) => user.subscriptionstatus === true &&
      user.subscriptionexpired > datadehoje.toISOString() &&
      (user.tipo_assinatura === "Plano Premium" || user.tipo_assinatura === "Plano Combo"));
  }

  if (Object.keys(assinantes_geral).length !== 0) {
    return (
      <div className="dashboardGrid">
        {graficoAtual === "assinantesAtivos" && <GraficoAssinantesAtivos />}
        {graficoAtual === "testesGratuitos" && <GraficoTestesGratuitos />}
        {graficoAtual === "cancelados" && <GraficoCancelados />}
        {graficoAtual === "faturamentoMensal" && <GraficoFaturamentoMensal />}
        {graficoAtual === "ticketMedio" && <GraficoTicketMedio />}

        <div className="botoesGraficos">
          <button className="btnAlterarGrafico" onClick={() => handleTrocarGrafico("assinantesAtivos")}>
            Assinantes Ativos
          </button>
          <button className="btnAlterarGrafico" onClick={() => handleTrocarGrafico("testesGratuitos")}>
            Testes Gratuitos
          </button>
          <button className="btnAlterarGrafico" onClick={() => handleTrocarGrafico("cancelados")}>
            Cancelados
          </button>
          <button className="btnAlterarGrafico" onClick={() => handleTrocarGrafico("faturamentoMensal")}>
            Faturamento Mensal
          </button>
          <button className="btnAlterarGrafico" onClick={() => handleTrocarGrafico("ticketMedio")}>
            Ticket Médio
          </button>
        </div>

        <div>
          <button className="btnAlterarGrafico" onClick={() => handleAtualizarDados()}>
            Atualizar Dados Diários
          </button>
        </div>
      </div>
    );
  }

}
export default DashboardGrid;