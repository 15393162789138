import React, { useEffect, useState } from "react";
import axios from "axios";
import { subMonths, subYears, subDays, isSameDay, format } from "date-fns";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Line,
} from "recharts";
import "./DashboardGrid.css";

const GET_DASHBOARD_TOTAL =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_numeros_totais/";


const GraficoFaturamentoMensal = () => {
    const [AssinantesNumerosTotais, setAssinantes] = useState([]);
    const [filterOption, setFilterOption] = useState("ultimos30dias");
    const [diaryData, setDiaryData] = useState([]);
    let [filtroPlano, setFiltroPlano] = useState("todos");


    const GerarGrafico = async () => {
        setDiaryData([])

        const inicioMes = new Date();
        inicioMes.setDate(1);
        const fimMes = new Date(inicioMes.getFullYear(), inicioMes.getMonth() + 1, 0);

        const assinantesDashboard = AssinantesNumerosTotais.map((item) => ({
            data: new Date(item.data),
            mensais: item.assinantes_mensais,
            premium: item.assinantes_premium,
            combo: item.assinantes_combo,
        }));

        // Loop para cada mês
        for (let currentMonth = inicioMes; currentMonth <= fimMes; currentMonth.setMonth(currentMonth.getMonth() + 1)) {
            const formattedDate = format(currentMonth, 'MM/yyyy ');

            const matchingItems = assinantesDashboard.filter((item) => {
                const itemMonth = new Date(item.data);
                return itemMonth.getMonth() === currentMonth.getMonth() && itemMonth.getFullYear() === currentMonth.getFullYear();
            });

            const ultimoItem = matchingItems[matchingItems.length - 1]; // Pega o último item do array
            const numAssinantes = ultimoItem ? Number(ultimoItem.mensais) + Number(ultimoItem.premium) + Number(ultimoItem.combo) : 0;
            const faturamento = ultimoItem ? ((Number(ultimoItem.mensais) * 79.90) + (Number(ultimoItem.premium) * 778.80) + (Number(ultimoItem.combo) * 1764)) : 0;
            const novoItem = {
                name: formattedDate,
                ticketMedio: ultimoItem ? (faturamento / numAssinantes).toFixed(2) : 0,                
            };            

            setDiaryData((prevDiaryData) => [...prevDiaryData, novoItem]);
        }
    }


    const fetchData = async () => {
        try {
            const assinantesTotal = await axios.get(GET_DASHBOARD_TOTAL);
            setAssinantes(assinantesTotal.data);

        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        async function fetchDataa() {
            await fetchData();
        }
        fetchDataa();
    }, []);

    useEffect(() => {
        GerarGrafico();
    }, [AssinantesNumerosTotais]);


    return (
        <div className="dashboardGrid">
            <div>
                <div className="dashboardGrid-chart">
                    Ticket Médio
                </div>
                <LineChart
                    width={750}
                    height={250}
                    data={diaryData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line
                        name="Ticket Médio"
                        type="monotone"
                        dataKey="ticketMedio"
                        stroke="#079FEB"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                    />
                </LineChart>
                <div className="selecoesNovosUsers">
                    <select className="plano-select" onChange={(e) => setFiltroPlano(e.target.value)}>
                        <option value="todos">Todos</option>
                        <option value="basic">Plano Basic</option>
                        <option value="premium">Plano Premium</option>
                        <option value="combo">Plano Combo</option>
                        <option value="testeGratuito">Teste Gratuito</option>
                        <option value="unionunmep">Union/Unmep</option>
                        <option value="parceiroUnmep">Parceiro Unmep</option>
                    </select>
                    <select className="dashboardGrid-select" onChange={(e) => setFilterOption(e.target.value)}>
                        <option value="esseMes">Esse mês</option>
                        <option value="hoje">Hoje</option>
                        <option value="ultimos7dias">Últimos 7 dias</option>
                        <option value="ultimos30dias">Últimos 30 dias</option>
                        <option value="ultimos3meses">Últimos 3 meses</option>
                        <option value="ultimos6meses">Últimos 6 meses</option>
                        <option value="ultimos12meses">Últimos 12 meses</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default GraficoFaturamentoMensal;

