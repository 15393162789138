import React, { useEffect, useState } from "react";
import axios from "axios";
import { isSameDay, format } from "date-fns";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Line,
} from "recharts";
import "./DashboardGrid.css";

const GET_DASHBOARD_TOTAL =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_numeros_totais/";
const GET_USERS_URL_STORE =
    "https://dashboard-api-bice.vercel.app/api/consultar_usuarios/";
const GET_DASHBOARD_USUARIOS =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_dados_usuarios/";


const GraficoTestesGratuitos = () => {
    const [AssinantesNumerosTotais, setAssinantes] = useState([]);
    const [AssinantesDadosUsuarios, setAssinantesDadosUsuarios] = useState([]);
    const [Usuarios, setUsuarios] = useState([]);
    const [filterOption, setFilterOption] = useState("ultimos30dias");
    const [diaryData, setDiaryData] = useState([]);

    const GerarGrafico = async () => {
        setDiaryData([])
        let dataInicio = new Date();
        let dataFim = new Date();
        let dataComparar = new Date();

        dataFim.setDate(dataFim.getDate());

        switch (filterOption) {           
            case "hoje":
                dataInicio.setDate(dataInicio.getDate());
                dataComparar.setDate(dataComparar.getDate() - 1);
                break;
            case "ultimos7dias":
                dataInicio.setDate(dataInicio.getDate() - 7);                
                dataComparar.setDate(dataComparar.getDate() - 14);
                break;
            case "ultimos30dias":
                dataInicio.setDate(dataInicio.getDate() - 30);
                dataComparar.setDate(dataComparar.getDate() - 60);
                break;
            case "ultimos3meses":
                dataInicio.setMonth(dataInicio.getMonth() - 3);
                dataComparar.setDate(dataComparar.getDate() - 180);
                break;
            case "ultimos6meses":
                dataInicio.setMonth(dataInicio.getMonth() - 6);
                dataComparar.setDate(dataComparar.getDate() - 360);
                break;
            case "ultimos12meses":
                dataInicio.setFullYear(dataInicio.getFullYear() - 1);
                dataComparar.setDate(dataComparar.getDate() - 720);
                break;
            default:
                dataInicio.setDate(1);
                dataFim = new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 0);
                break;
        }

        // coloque no const NumeroCancelados um map do AssinantesNumerosTotais com a data e o numero de cancelados
        const assinantesDashboard = AssinantesDadosUsuarios.map((item) => ({
            id: item.id,
            dataLastCompra: new Date(new Date(item.data_ultima_compra).getTime() + (24 * 60 * 60 * 1000)),
            recorrencia: item.recorrente,
            tipo_assinatura: item.tipo_plano,
            plano_anterior: item.plano_anterior,
        }));
 
        for (let currentDay = dataInicio; currentDay <= dataFim; currentDay.setDate(currentDay.getDate() + 1)) {
            const formattedDate = format(currentDay, 'dd/MM ');
            const matchingItem = assinantesDashboard.filter(item => item.plano_anterior === "Teste gratuito" && isSameDay(item.dataLastCompra, currentDay));
            const taxaConversao = assinantesDashboard.filter((assinante) => assinante.plano_anterior === "Teste gratuito" && assinante.tipo_assinatura !== "Teste gratuito" && isSameDay(assinante.dataLastCompra, currentDay));

            // Esse trecho vai comparar o filtro atual com o mesmo período selecionado anterior para mostrar o comparativo
            // Exemplo: filtro dos ultimos 30 dias, o comparativo vai mostrar 30 dias anteriores aos ultimos 30 dias
            const novoItem = {
                name: formattedDate,
                novosTestes: matchingItem ? Number(matchingItem.length) : 0,
                taxaConversao: taxaConversao ? Number(taxaConversao.length) : 0,
            };

            setDiaryData((prevDiaryData) => [...prevDiaryData, novoItem]);
        }
        // Loop para cada dia do mês 

    }

    function updateFiltro() {
        GerarGrafico();
    }

    useEffect(() => {
        updateFiltro();
    }, [filterOption]);


    const fetchData = async () => {
        try {
            await Promise.all([
                axios(GET_DASHBOARD_TOTAL),
                axios(GET_DASHBOARD_USUARIOS),
                axios(GET_USERS_URL_STORE),
              ]).then(res => {
                setAssinantes(res[0].data);
                setAssinantesDadosUsuarios(res[1].data);
                setUsuarios(res[2].data);
              })
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        async function fetchDataa() {
            await fetchData();
        }
        fetchDataa();
    }, []);

    useEffect(() => {
        GerarGrafico();
    }, [AssinantesNumerosTotais, AssinantesDadosUsuarios,  Usuarios]);


    return (
        <div className="dashboardGrid">
            <div>
                <div className="dashboardGrid-chart">
                    Testes Gratuitos
                </div>
                <LineChart
                    width={750}
                    height={250}
                    data={diaryData}
                    margin={{ top: 0, right: 5, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line
                        name="Novos Usuários"
                        type="monotone"
                        dataKey="novosTestes"
                        stroke="#079FEB"
                        fillOpacity={1}
                    />                    
                    <Line
                        name="Taxa de Conversão"
                        type="monotone"
                        dataKey="taxaConversao"
                        stroke="#FFB800"
                        fillOpacity={1}
                    />
                </LineChart>
                <div className="selecoesNovosUsers">
                    <select className="dashboardGrid-select" onChange={(e) => setFilterOption(e.target.value)} defaultValue={"ultimos30dias"}>
                        <option value="hoje">Hoje</option>
                        <option value="ultimos7dias">Últimos 7 dias</option>
                        <option value="ultimos30dias">Últimos 30 dias</option>
                        <option value="ultimos3meses">Últimos 3 meses</option>
                        <option value="ultimos6meses">Últimos 6 meses</option>
                        <option value="ultimos12meses">Últimos 12 meses</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default GraficoTestesGratuitos;

