import React, { useEffect, useState } from "react";
import axios from "axios";
import { isSameDay, format } from "date-fns";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Line,
} from "recharts";
import "./DashboardGrid.css";

const GET_DASHBOARD_TOTAL =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_numeros_totais/";
const GET_USERS_URL_STORE =
    "https://dashboard-api-bice.vercel.app/api/consultar_usuarios/";
const GET_DASHBOARD_USUARIOS =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_dados_usuarios/";


const GraficoAssinantesAtivos = () => {
    const [AssinantesNumerosTotais, setAssinantes] = useState([]);
    let [AssinantesDadosUsuarios, setAssinantesDadosUsuarios] = useState([]);
    const [Usuarios, setUsuarios] = useState([]);
    let [filterOption, setFilterOption] = useState("ultimos30dias");
    let [filtroPlano, setFiltroPlano] = useState("todos");
    const [diaryData, setDiaryData] = useState([]);

    const GerarGrafico = async () => {
        setDiaryData([])
        let dataInicio = new Date();
        let dataFim = new Date();
        dataFim.setDate(dataFim.getDate());

        switch (filterOption) {
            case "hoje":
                dataInicio.setDate(dataInicio.getDate());
                break;
            case "ultimos7dias":
                dataInicio.setDate(dataInicio.getDate() - 7);
                break;
            case "ultimos30dias":
                dataInicio.setDate(dataInicio.getDate() - 30);
                break;
            case "ultimos3meses":
                dataInicio.setMonth(dataInicio.getMonth() - 3);
                break;
            case "ultimos6meses":
                dataInicio.setMonth(dataInicio.getMonth() - 6);
                break;
            case "ultimos12meses":
                dataInicio.setFullYear(dataInicio.getFullYear() - 1);
                break;
            default:
                dataInicio.setDate(1);
                dataFim = new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 0);
                break;
        }

        const assinantesNumTotais = AssinantesNumerosTotais.map((item) => ({
            id: item.id,
            data: new Date(new Date(item.data).getTime() + (24 * 60 * 60 * 1000)),
            total_registrados: item.total_registrados,
            total_mensais: item.assinantes_mensais,
            total_anuais: item.assinantes_anuais,
            total_testes: item.testes_gratuitos,
            total_premium: item.assinantes_premium,
            total_combo: item.assinantes_combo,
            total_assinaturas_canceladas: item.assinaturas_canceladas,
            total_parceiros_unmep: item.parceiros_unmep,
            total_union_unmep: item.union_unmep,
        }));


        // Loop para cada dia do mês 
        for (let currentDay = dataInicio; currentDay <= dataFim; currentDay.setDate(currentDay.getDate() + 1)) {
            const formattedDate = format(currentDay, 'dd/MM ');

            let matchingItem = assinantesNumTotais.filter((item) => {
                return isSameDay(item.data, currentDay);
            })

            let novoItem = {
                name: formattedDate,
                mensais: matchingItem[0] ? matchingItem[0].total_mensais : 0,
                premium: matchingItem[0] ? matchingItem[0].total_premium : 0,
                combo: matchingItem[0] ? matchingItem[0].total_combo : 0,
                testes: matchingItem[0] ? matchingItem[0].total_testes : 0,
                parceirosUnmep: matchingItem[0] ? matchingItem[0].total_parceiros_unmep : 0,
                unionUnmep: matchingItem[0] ? matchingItem[0].total_union_unmep : 0,
            };

            setDiaryData((prevDiaryData) => [...prevDiaryData, novoItem]);
        }
    }

    function updateFiltro() {
        GerarGrafico();
    }

    useEffect(() => {
        updateFiltro();
    }, [filterOption, filtroPlano]);


    const fetchData = async () => {
        try {
            const assinantesTotal = await axios.get(GET_DASHBOARD_TOTAL);
            const assinantesDadosUsuarios = await axios.get(GET_DASHBOARD_USUARIOS);
            const Usuarios = await axios.get(GET_USERS_URL_STORE);
            setAssinantes(assinantesTotal.data);
            setAssinantesDadosUsuarios(assinantesDadosUsuarios.data);
            setUsuarios(Usuarios.data);

        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        async function fetchDataa() {
            await fetchData();
        }
        fetchDataa();
    }, []);

    useEffect(() => {
        GerarGrafico();
    }, [Usuarios]);


    return (
        <div className="dashboardGrid">
            <div>
                <div className="dashboardGrid-chart">
                    Assinantes Ativos
                </div>
                <LineChart
                    width={750}
                    height={250}
                    data={diaryData}
                    margin={{ top: 0, right: 5, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line
                        name="Plano Basic"
                        type="monotone"
                        dataKey="mensais"
                        stroke="#079FEB"
                        fillOpacity={1}
                        visibility={filtroPlano === "basic" || filtroPlano === "todos" ? "visible" : "hidden"}
                    />
                    <Line
                        name="Plano Premium"
                        type="monotone"
                        dataKey="premium"
                        stroke="#617E7E"
                        fillOpacity={1}
                        visibility={filtroPlano === "premium" || filtroPlano === "todos" ? "visible" : "hidden"}
                    />
                    <Line
                        name="Plano Combo"
                        type="monotone"
                        dataKey="combo"
                        stroke="#F5A623"
                        fillOpacity={1}
                        visibility={filtroPlano === "combo" || filtroPlano === "todos" ? "visible" : "hidden"}
                    />
                    <Line
                        name="Teste Gratuito"
                        type="monotone"
                        dataKey="testes"
                        stroke="#00034F"
                        fillOpacity={1}
                        visibility={filtroPlano === "testeGratuito" || filtroPlano === "todos" ? "visible" : "hidden"}
                    />
                    <Line
                        name="Union/UnMEP"
                        type="monotone"
                        dataKey="unionUnmep"
                        stroke="#6787AD"
                        fillOpacity={1}
                        visibility={filtroPlano === "unionunmep" || filtroPlano === "todos" ? "visible" : "hidden"}
                    />
                    <Line
                        name="Parceiro UnMEP"
                        type="monotone"
                        dataKey="parceirosUnmep"
                        stroke="#40427B"
                        fillOpacity={1}
                        visibility={filtroPlano === "parceiroUnmep" || filtroPlano === "todos" ? "visible" : "hidden"}
                    />
                </LineChart>
                <div className="selecoesNovosUsers">
                    <select className="plano-select" onChange={(e) => setFiltroPlano(e.target.value)}>
                        <option value="todos">Todos</option>
                        <option value="basic">Plano Basic</option>
                        <option value="premium">Plano Premium</option>
                        <option value="combo">Plano Combo</option>
                        <option value="testeGratuito">Teste Gratuito</option>
                        <option value="unionunmep">Union/Unmep</option>
                        <option value="parceiroUnmep">Parceiro Unmep</option>
                    </select>

                    <select className="dashboardGrid-select" onChange={(e) => setFilterOption(e.target.value)} defaultValue={"ultimos30dias"}>
                        <option value="hoje">Hoje</option>
                        <option value="ultimos7dias">Últimos 7 dias</option>
                        <option value="ultimos30dias">Últimos 30 dias</option>
                        <option value="ultimos3meses">Últimos 3 meses</option>
                        <option value="ultimos6meses">Últimos 6 meses</option>
                        <option value="ultimos12meses">Últimos 12 meses</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default GraficoAssinantesAtivos;

