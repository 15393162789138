import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Line,
} from "recharts";
import { subMonths, subYears, subDays, format, isSameDay, set } from "date-fns";
import "./DashboardGrid.css";

const GET_DASHBOARD_TOTAL =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_numeros_totais/";
const GET_DASHBOARD_USUARIOS =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_dados_usuarios/";
const GET_USERS_URL_CANCELADOS =
    "https://dashboard-api-bice.vercel.app/api/consultar_assinantes_cancelados/";

const GraficoCancelados = () => {
    const [AssinantesNumerosTotais, setAssinantes] = useState([]);
    const [AssinantesDadosUsuarios, setAssinantesDadosUsuarios] = useState([]);
    const [Usuarios_cancelados, setUsuariosCancelados] = useState([]);
    let [filterOption, setFilterOption] = useState("ultimos30dias");

    const [diaryData, setDiaryData] = useState([]);


    const GerarGrafico = async () => {
        setDiaryData([])
        let dataInicio = new Date();
        let dataFim = new Date();
        let dataComparar = new Date();
        dataFim.setDate(dataFim.getDate());
        switch (filterOption) {           
            case "hoje":
                dataInicio.setDate(dataInicio.getDate());
                
                dataComparar.setDate(dataComparar.getDate() - 2);
                break;
            case "ultimos7dias":
                dataInicio.setDate(dataInicio.getDate() - 7);
                dataFim.setDate(dataFim.getDate() - 1);
                dataComparar.setDate(dataComparar.getDate() - 14);
                break;
            case "ultimos30dias":
                dataInicio.setDate(dataInicio.getDate() - 30);
                dataComparar.setDate(dataComparar.getDate() - 60);
                break;
            case "ultimos3meses":
                dataInicio.setMonth(dataInicio.getMonth() - 3);
                dataComparar.setDate(dataComparar.getDate() - 180);
                break;
            case "ultimos6meses":
                dataInicio.setMonth(dataInicio.getMonth() - 6);
                dataComparar.setDate(dataComparar.getDate() - 360);
                break;
            case "ultimos12meses":
                dataInicio.setFullYear(dataInicio.getFullYear() - 1);
                dataComparar.setDate(dataComparar.getDate() - 720);
                break;
            default:
                dataInicio.setDate(1);
                dataFim = new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 0);
                break;
        }
        
        const assinantesDashboard = AssinantesDadosUsuarios.map((item) => ({
            id: item.id,
            dataLastCompra: new Date(item.data_ultima_compra),
            recorrencia: item.recorrente,
        }));
        const usuariosCancelados = Usuarios_cancelados.map((item) => ({
            id: item.id,
            stripeId: item.stripe_id,
            dataCancelamento: new Date(item.data_cancelamento),
        }));

        // Loop para cada dia do mês 
        for (let currentDay = dataInicio; currentDay <= dataFim; currentDay.setDate(currentDay.getDate() + 1)) {
            const formattedDate = format(currentDay, 'dd/MM ');

            // Encontrar o item correspondente em NumeroCancelados

            /*const matchingItem = NumeroCancelados.find((item) => {
                const itemDate = new Date(item.data);
                return isSameDay(itemDate, currentDay);
            });*/
            // taxa de churn será a soma dos usuarios cancelados nos ultimos 30 dias dividido pelo numero de usuarios ativos no mesmo periodo
            // usuarios cancelados nos ultimos 30 dias
            const usuariosCancelados30dias = usuariosCancelados.filter((usuario) => {
                const usuarioDate = new Date(usuario.dataCancelamento);

                // Verifica se a data de cancelamento
                const trintaDiasAtras = subDays(currentDay, 30);

                return (
                    usuarioDate >= trintaDiasAtras
                );
            });

            // usuarios ativos nos ultimos 30 dias
            const usuariosAtivos30dias = assinantesDashboard.filter((usuario) => {

                // Verifica se a data de cancelamento
                const trintaDiasAtras = subDays(currentDay, 30);

                return (
                    usuario.dataLastCompra >= trintaDiasAtras
                );
            });
            const canceladosHoje = usuariosCancelados.filter((usuario) => {
                const usuarioDate = new Date(usuario.dataCancelamento);
                return (
                    isSameDay(usuarioDate, currentDay)
                );
            });

            const novoItem = {
                name: formattedDate,
                assinantesCancelados: canceladosHoje ? Number(canceladosHoje.length) : 0,
                taxaChurn: usuariosCancelados30dias
                    ? (Number(usuariosCancelados30dias.length) / Number(usuariosAtivos30dias.length)).toFixed(2)
                    : 0,
            };
            
            setDiaryData((prevDiaryData) => [...prevDiaryData, novoItem]);
        }
    }

    const fetchData = async () => {

        try {
            await Promise.all([
                axios.get(GET_DASHBOARD_TOTAL),
                axios.get(GET_DASHBOARD_USUARIOS),
                axios.get(GET_USERS_URL_CANCELADOS),
            ]).then((results) => setAssinantes(results[0].data))
            const assinantesDadosUsuarios = await axios.get(GET_DASHBOARD_USUARIOS);
            const assinantesCancelados = await axios.get(GET_USERS_URL_CANCELADOS);
            setAssinantesDadosUsuarios(assinantesDadosUsuarios.data);
            setUsuariosCancelados(assinantesCancelados.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }

    };

    useEffect(() => {
        async function fetchDataa() {
            await fetchData();
        }
        fetchDataa();
    }, []);

    useEffect(() => {
        GerarGrafico();
    }, [AssinantesNumerosTotais, AssinantesDadosUsuarios, Usuarios_cancelados]);

    function updateFiltro() {
        GerarGrafico();
    }

    useEffect(() => {
        updateFiltro();
    }, [filterOption]);

    return (
        <div className="dashboardGrid">
            <div>
                <div className="dashboardGrid-chart">Cancelamentos</div>
                <LineChart
                    width={750}
                    height={250}
                    data={diaryData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <YAxis orientation="right" type="number" yAxisId="2" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line
                        name="Total de Cancelamentos"
                        type="monotone"
                        dataKey="assinantesCancelados"
                        stroke="#900"
                    />
                    <Line
                        name="Taxa de Churn"
                        type="natural"
                        dataKey="taxaChurn"
                        stroke="#FFA500"                        
                        yAxisId="2"

                    />
                </LineChart>
                <div className="selecoesNovosUsers">
                    <select className="dashboardGrid-select" onChange={(e) => setFilterOption(e.target.value)} defaultValue={"ultimos30dias"}>
                        <option value="hoje">Hoje</option>
                        <option value="ultimos7dias">Últimos 7 dias</option>
                        <option value="ultimos30dias">Últimos 30 dias</option>
                        <option value="ultimos3meses">Últimos 3 meses</option>
                        <option value="ultimos6meses">Últimos 6 meses</option>
                        <option value="ultimos12meses">Últimos 12 meses</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default GraficoCancelados;
