import React, { useEffect, useState } from "react";
import axios from "axios";
import { subMonths, subYears, subDays, isSameDay, format } from "date-fns";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Line,
} from "recharts";
import { Card, Statistic } from "semantic-ui-react";
import "./DashboardGrid.css";

const GET_DASHBOARD_TOTAL =
    "https://dashboard-api-bice.vercel.app/api/coletar_assinantes_numeros_totais/";


const GraficoFaturamentoMensal = () => {
    const [AssinantesNumerosTotais, setAssinantes] = useState([]);
    let [filterOption, setFilterOption] = useState("MesPassado");
    const [diaryData, setDiaryData] = useState([]);


    const GerarGrafico = async () => {
        setDiaryData([])

        let dataInicio = new Date();
        let dataFim = new Date();

        switch (filterOption) {           
            case "doInicioAteHoje":
                // pegar do inicio do mes atual ate hoje
                dataInicio.setDate(1);
                dataFim.setDate(dataFim.getDate());
                break;
            case "MesPassado":
                // pegar do inicio do mes passado ate o fim do mes passado
                dataInicio.setMonth(dataInicio.getMonth() - 1);
                dataInicio.setDate(1);
                dataFim.setDate(0);                
                break;
            case "3MesesAtras":
                // 3 meses atras
                dataInicio.setMonth(dataInicio.getMonth() - 3);
                dataInicio.setDate(1);
                dataFim.setDate(0);
               
                break;
            case "6MesesAtras":
                // 6 meses atras
                dataInicio.setMonth(dataInicio.getMonth() - 6);
                dataInicio.setDate(1);
                dataFim.setDate(0);                
                break;
            case "12MesesAtras":
                // 12 meses atras
                dataInicio.setMonth(dataInicio.getMonth() - 12);
                dataInicio.setDate(1);
                dataFim.setDate(0);               
                break;
            case "ultimos12meses":
                dataInicio.setFullYear(dataInicio.getFullYear() - 1);
                dataFim.setDate(dataFim.getDate() - 1);
                break;
            default:
                dataInicio.setDate(1);
                dataFim = new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 0);
                break;
        }
        const assinantesDashboard = AssinantesNumerosTotais.map((item) => ({
            data: new Date(item.data),
            mensais: item.assinantes_mensais,
            premium: item.assinantes_premium,
            combo: item.assinantes_combo,
        }));

        // Loop para cada mês
        for (let currentMonth = dataInicio; currentMonth <= dataFim; currentMonth.setMonth(currentMonth.getMonth() + 1)) {
            const formattedDate = format(currentMonth, 'MM/yyyy ');

            const matchingItems = assinantesDashboard.filter((item) => {
                const itemMonth = new Date(item.data);
                return itemMonth.getMonth() === currentMonth.getMonth() && itemMonth.getFullYear() === currentMonth.getFullYear();
            });

            const ultimoItem = matchingItems[matchingItems.length - 1]; // Pega o último item do array

            const novoItem = {
                name: formattedDate,
                faturamentoMensais: ultimoItem ? (Number(ultimoItem.mensais) * 79.90).toFixed(2) : 0,
                faturamentoPremium: ultimoItem ? (Number((ultimoItem.premium) * 778.80) / 12).toFixed(2) : 0,
                faturamentoCombo: ultimoItem ? (Number((ultimoItem.combo) * 1760.40) / 12).toFixed(2) : 0,
            };

            setDiaryData((prevDiaryData) => [...prevDiaryData, novoItem]);
        }
    }


    const fetchData = async () => {
        try {
            const assinantesTotal = await axios.get(GET_DASHBOARD_TOTAL);
            setAssinantes(assinantesTotal.data);

        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        async function fetchDataa() {
            await fetchData();
        }
        fetchDataa();
    }, []);

    useEffect(() => {
        GerarGrafico();
    }, [AssinantesNumerosTotais]);


    return (
        <div className="dashboardGrid">
            <div>
                <div className="dashboardGrid-chart">
                    Faturamento Mensal
                </div>
                <LineChart
                    width={750}
                    height={250}
                    data={diaryData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#079FEB" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line
                        name="Assinantes Mensais R$"
                        type="monotone"
                        dataKey="faturamentoMensais"
                        stroke="#079FEB"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                    />
                    <Line
                        name="Assinantes Premium R$"
                        type="monotone"
                        dataKey="faturamentoPremium"
                        stroke="#B63A3A"
                        fillOpacity={1}
                        fill="url(#colorPv)"
                    />
                    <Line
                        name="Assinantes Combo R$"
                        type="monotone"
                        dataKey="faturamentoCombo"
                        stroke="#FFA500"
                        fillOpacity={1}
                        fill="url(#colorPv)"
                    />
                </LineChart>
                <select className="dashboardGrid-select" onChange={(e) => setFilterOption(e.target.value)} defaultValue={"ultimos30dias"}>
                    <option value="hoje">Hoje</option>
                    <option value="ultimos7dias">Últimos 7 dias</option>
                    <option value="ultimos30dias">Últimos 30 dias</option>
                    <option value="ultimos3meses">Últimos 3 meses</option>
                    <option value="ultimos6meses">Últimos 6 meses</option>
                    <option value="ultimos12meses">Últimos 12 meses</option>
                </select>
            </div>
        </div>
    );
}

export default GraficoFaturamentoMensal;

