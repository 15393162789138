import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Loader, Dimmer, Button } from "semantic-ui-react";

import "./DashboardTable.css";

const GET_USERS_URL_STORE = "https://dashboard-api-bice.vercel.app/api/consultar_usuarios/";
const GET_USERS_URL_HISTORY = "https://dashboard-api-bice.vercel.app/api/coletar_checkout_order/";

function TabelaFantasmas() {
    const [usuariosGeral, setUsuarios] = useState([]);
    const [historicoCompras, setHistoricoCompras] = useState([]);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterType, setFilterType] = useState("all");

    // Função para alternar a direção da classificação
    const toggleSortDirection = () => {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    };
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    // Função para classificar os dados com base na coluna selecionada
    const sortData = (data, column, direction) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (direction === "asc") {
                return typeof aValue === "string"
                    ? aValue.localeCompare(bValue)
                    : aValue < bValue
                        ? -1
                        : 1;
            } else {
                return typeof aValue === "string"
                    ? bValue.localeCompare(aValue)
                    : aValue > bValue
                        ? 1
                        : -1;
            }
        });
    };
    // Manipulador de clique para os cabeçalhos das colunas
    const handleHeaderClick = (column) => {
        if (sortedColumn === column) {
            toggleSortDirection();
        } else {
            setSortedColumn(column);
            setSortDirection("asc");
        }
    };

    const handleDeslogarUsuario = async (userEmail) => {
        if (window.confirm("Deseja deslogar o usuário: " + userEmail + "?") === true) {

            try {
                await axios.post("https://api.unmep.com.br/deslogarusuario", { userEmail: userEmail }).then(res => {
                    if (res.status === 200) {
                        alert("O usuário foi deslogado com sucesso!")
                    }
                })
            } catch (er) {
                alert("Falha ao deslogar o usuário: " + er)
            }

        }
    };

    useEffect(() => {
        const fetchUsuarios = async () => {
            const response = await axios.get(GET_USERS_URL_STORE);
            setUsuarios(response.data);
            const responseHistory = await axios.get(GET_USERS_URL_HISTORY);
            setHistoricoCompras(responseHistory.data);
            setLoading(false);
        };
        fetchUsuarios();
    }, []);

    useEffect(() => {
        handleHeaderClick("quantidade");
    }, []);

    /*function formatarData(data) {
        const dataObj = new Date(data);
        const dia = dataObj.getDate().toString().padStart(2, "0");
        const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
        const ano = dataObj.getFullYear().toString().slice(-2);

        return `${dia} / ${mes} / ${ano}`;
    }*/
    // filtragem dos usuarios do usuariosGeral que tem o parâmetro tipo_assinatura = "Não Assinante" mas que também não estejam na lista de historicoCompras
    const usuariosNaoAssinantes = usuariosGeral.filter((usuario) => {
        const usuarioNaoAssinante = (usuario.tipo_assinatura.toLowerCase() === "não assinante" ||
            usuario.tipo_assinatura.toLowerCase() === "sem assinatura") && usuario.periodo_teste === false;
        const usuarioHistorico = historicoCompras.find((historico) => historico.stripe_user_id === usuario.stripe_id);
        return usuarioNaoAssinante && !usuarioHistorico;
    });

    const usuariosAssinantes = usuariosGeral.filter((usuario) => {
        const usuarioAssinante = usuario.tipo_assinatura.toLowerCase() !== "não assinante"
            && usuario.tipo_assinatura.toLowerCase() !== "sem assinatura"
            && new Date(usuario.subscriptionexpired) > new Date();
        return usuarioAssinante;
    });

    const AnuaisAVencer = usuariosAssinantes.filter((usuario) => {
        const usuarioAnual = usuario.tipo_assinatura.toLowerCase() === "plano combo" || usuario.tipo_assinatura.toLowerCase() === "plano premium";
        const dataVencimento = new Date(usuario.subscriptionexpired);
        const dataLimite = new Date();

        // Adiciona 30 dias à data atual
        dataLimite.setMonth(dataLimite.getMonth() + 1);

        return usuarioAnual && dataVencimento <= dataLimite;
    });




    // Unir usuários não assinantes e assinantes
    const todosUsuarios = [...usuariosNaoAssinantes, ...usuariosAssinantes];

    // Aplicar classificação aos dados com base no estado de classificação atual
    const sortedUsuarios = sortData(todosUsuarios, sortedColumn, sortDirection);

    // Filtrar resultados com base no termo de pesquisa
    const filteredUsuarios = sortedUsuarios.filter((usuario) => {
        const nome = usuario.first_name.toLowerCase();
        const email = usuario.email.toLowerCase();
        const term = searchTerm.toLowerCase();
        const matchTerm = nome.includes(term) || email.includes(term);

        if (filterType === "assinantes") {
            return matchTerm && usuariosAssinantes.includes(usuario);
        } else if (filterType === "fantasmas") {
            return matchTerm && usuariosNaoAssinantes.includes(usuario);
        } else if (filterType === "all") {
            return matchTerm && todosUsuarios.includes(usuario);
        } else if (filterType === "AnuaisAVencer") {
            return matchTerm && AnuaisAVencer.includes(usuario);
        }

        return matchTerm;
    });

    return (
        <div className="dashboardTable-wrapper">
            {loading ? (
                <Dimmer active inverted>
                    <Loader inverted>Carregando</Loader>
                </Dimmer>
            ) : (
                <div className="dashboardTable-wrapper">
                    <input
                        type="text"
                        placeholder="Pesquisar por nome ou email"
                        className="custom-input" // Nome da classe CSS
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <div className="filters">
                        <Button
                            active={filterType === "all"}
                            onClick={() => setFilterType("all")}
                        >
                            Todos
                        </Button>
                        <Button
                            active={filterType === "assinantes"}
                            onClick={() => setFilterType("assinantes")}
                        >
                            Assinantes Ativos
                        </Button>
                        <Button
                            active={filterType === "fantasmas"}
                            onClick={() => setFilterType("fantasmas")}
                        >
                            Usuarios Fantasmas
                        </Button>
                        <Button
                            active={filterType === "AnuaisAVencer"}
                            onClick={() => setFilterType("AnuaisAVencer")}
                        >
                            Anuais a Vencer
                        </Button>
                    </div>
                    <div className="dashboardTable-title">
                        <h2>Quantidades totais</h2>
                    </div>
                    <div className="dashboardTable-quantidades">
                        <div className="dashboardTable-quantidades-item">
                            <h3>Assinantes Ativos</h3>
                            <p>{usuariosAssinantes.length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Assinantes Premium</h3>
                            <p>{usuariosAssinantes.filter((usuario) => usuario.tipo_assinatura.toLowerCase() === "plano premium").length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Assinantes Combo</h3>
                            <p>{usuariosAssinantes.filter((usuario) => usuario.tipo_assinatura.toLowerCase() === "plano combo").length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Assinantes Basic</h3>
                            <p>{usuariosAssinantes.filter((usuario) => usuario.tipo_assinatura.toLowerCase() === "plano basic").length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Testes gratuitos</h3>
                            <p>{usuariosAssinantes.filter((usuario) => usuario.tipo_assinatura.toLowerCase() === "teste gratuito").length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Union/UnMEP</h3>
                            <p>{usuariosAssinantes.filter((usuario) => usuario.tipo_assinatura.toLowerCase() === "union/unmep").length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Parceiros UnMEP</h3>
                            <p>{usuariosAssinantes.filter((usuario) => usuario.tipo_assinatura.toLowerCase().includes("parceiro unmep")).length}</p>
                        </div>
                        <div className="dashboardTable-quantidades-item">
                            <h3>Usuários Fantasmas</h3>
                            <p>{usuariosNaoAssinantes.length}</p>
                        </div>

                    </div>



                    <Table textAlign="center" celled padded className="centralized-table">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    onClick={() => handleHeaderClick("quantidade")}
                                    className="dashboardTable-header"
                                >
                                    Quantidade{" "}
                                    {sortedColumn === "quantidade"
                                        ? sortDirection === "asc"
                                            ? "▲"
                                            : "▼"
                                        : null}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    onClick={() => handleHeaderClick("nome")}
                                    className="dashboardTable-header"
                                >
                                    Nome{" "}
                                    {sortedColumn === "nome"
                                        ? sortDirection === "asc"
                                            ? "▲"
                                            : "▼"
                                        : null}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    onClick={() => handleHeaderClick("email")}
                                    className="dashboardTable-header"
                                >
                                    Email{" "}
                                    {sortedColumn === "email"
                                        ? sortDirection === "asc"
                                            ? "▲"
                                            : "▼"
                                        : null}
                                </Table.HeaderCell>

                                <Table.HeaderCell
                                    onClick={() => handleHeaderClick("plano")}
                                    className="dashboardTable-header"
                                >
                                    Plano{" "}
                                    {sortedColumn === "plano"
                                        ? sortDirection === "asc"
                                            ? "▲"
                                            : "▼"
                                        : null}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    onClick={() => handleHeaderClick("subscriptionexpired")}
                                    className="dashboardTable-header"
                                >
                                    Vencimento{" "}
                                    {sortedColumn === "subscriptionexpired"
                                        ? sortDirection === "asc"
                                            ? "▲"
                                            : "▼"
                                        : null}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Whatsapp
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Administrativo
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {filteredUsuarios.map(
                                (item, i) => (
                                    (
                                        <Table.Row key={i}>
                                            <Table.Cell width={1}>
                                                <div>{i + 1}</div>
                                            </Table.Cell>
                                            <Table.Cell width={4} className="dashboardTable-cell-name">
                                                <div>{item.first_name}</div>
                                            </Table.Cell>
                                            <Table.Cell width={4}>
                                                <div>{item.email}</div>
                                            </Table.Cell>
                                            <Table.Cell width={4}>
                                                <div>{item.tipo_assinatura}</div>
                                            </Table.Cell>
                                            <Table.Cell width={1}>
                                                <div>{new Date(item.subscriptionexpired).toLocaleDateString()}</div>
                                            </Table.Cell>
                                            <Table.Cell width={2}>
                                                <div>
                                                    <button className="dashboardTable-buttonWhatsapp">
                                                        <a
                                                            href={`https://api.whatsapp.com/send?phone=55${item.userphone}&text=Olá, ${item.first_name}!`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{ color: "white" }}
                                                        >
                                                            Whatsapp
                                                        </a>
                                                    </button>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell width={2}>
                                                <div>
                                                    <button className="dashboardTable-buttonWhatsapp" onClick={() => handleDeslogarUsuario(item.email)}>
                                                        Deslogar Usuário
                                                    </button>
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                )
                            )}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </div>
    );
}

export default TabelaFantasmas;
