import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Loader, Dimmer, Button } from "semantic-ui-react";
import TabelaFantasmas from "./tabelaFantasmas";
import "./DashboardTable.css";

function DashboardTable() {

  return (
    <div className="dashboardTable-wrapper">
      <div className="dashboardTable-wrapper">
        <TabelaFantasmas />
      </div>
    </div>
  );
}

export default DashboardTable;
