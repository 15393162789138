import React from "react";
import { Header } from "semantic-ui-react";
import { useState, useEffect } from "react";
import 'semantic-ui-css/semantic.min.css'
import './App.css'

import DashboardGrid from "./components/DashboardGrid";
import DashboardTable from "./components/DashboardTable";

const THREE_MINUTES = 1000 * 60 * 3; // 3 minutos pra pedir a senha novamente

function App() {
  const [autenticado, setAutenticado] = React.useState(false);
  const [token, setToken] = React.useState(null);

  useEffect(() => {
    const tokenStr = localStorage.getItem("token");
    if (tokenStr) {
      const ultimaTentativaStr = localStorage.getItem("ultimaTentativa");
      if (ultimaTentativaStr && (Date.now() - parseInt(ultimaTentativaStr)) < THREE_MINUTES) {
        setToken(tokenStr);
        setAutenticado(true);
      }
    }
  }, []);

  const handleLogin = (senha) => {
    if (senha === "@12unmep") {
      const token = Math.random().toString(36).substr(2)
      setAutenticado(true);
      setToken(token);
      localStorage.setItem("token", token);
      localStorage.setItem("ultimaTentativa", Date.now());
    } else {
      alert("Senha incorreta!");
    }
  };

  const isAutenticado = () => {
    return token && (Date.now() - localStorage.getItem("ultimaTentativa")) < THREE_MINUTES;
  };

  return (
    <div className="App">
      {isAutenticado() ? (
        <div>
          <header className="App-header">
            <h2>UnMEP Dashboard</h2>
          </header>
          <div className="App-main">
            <DashboardGrid />
            <DashboardTable />
          </div>
        </div>
      ) : (
        <div className="App-login">
          <div className="login-container">
            <h1>Digite a senha para acessar o Dashboard:</h1>
            <div className="form-container">
              <input type="password" onKeyPress={(e) => { if (e.key === "Enter") handleLogin(e.target.value) }} />
              <button onClick={() => handleLogin(document.querySelector("input").value)}>Entrar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;








